<!-- 他的关注 -->
<template>
    <div class="_postMessage">
        <div class="moban_tou flex align-items">
            <div class="fenlei" v-for="(item, index) in liebiao" :key="index" :class="{ act: timuNum == index }">
                {{ item }}
                <div class="sanjiao"></div>
            </div>
        </div>
        <!-- 表格 -->
        <div class="w-full biao">
            <div class="biaoshen">
                <!-- 模板 -->
                <div class="w-full flex hang align-items" v-for="(item, index) in list" :key="index"
                    @click="yonghu(item.user_id)">
                    <div class="flex align-items">
                        <div class="qitren">
                            <img v-if="item.usehdimage != ''" :src="item.usehdimage" class="w-full h-full" alt="">
                            <img v-else src="../../../assets/img/one/weizhiyonghu.png" class="w-full h-full" alt="">
                        </div>
                        <div>
                            <div class="fontSix">{{ item.usename }}</div>
                            <div class="fontSeven">{{ '关注了他' }}</div>
                        </div>
                    </div>
                    <div class="fontEight">
                        关注日期: {{ item.followtime }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from '@/api/modules/other.js'
export default {
    data() {
        return {
            liebiao: ['关注'],
            timuNum: 0,
            page: 1,
            pageLine: 10,
            list: []
        }
    },
    mounted() {
        this.useFollowlike()
    },
    methods: {
        async useFollowlike() {
            let res = await API.otherFolcomfollows({
                useid: this.$store.state.loginMessage.id,
                page: this.page,
                pageLine: this.pageLine
            }).then(res => {
                if (res.code == 1) {
                    this.list = res.data.list
                    this.$parent.totals(res.data.total)
                }
            })
        },
        pages(num) {
            this.page = num
            this.artileContentContor()
        },
        zongye(num) {
            this.pageLine = num
            this.artileContentContor()
        },
        // 其他用户
        yonghu(id) {
         
            // this.$store.state.loginMessage.id = id
            // this.jump('other', this.$store.state.loginMessage)
        },
    },
}
</script>

<style scoped lang="scss">
._postMessage {
    width: 100%;
}

.moban_tou {
    height: 56px;

    .fenlei {
        height: 100%;
        text-align: center;
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #36383A;
        letter-spacing: 0;
        font-weight: 500;
        padding: 0 29px 0 37px;
        line-height: 61px;
        position: relative;
        cursor: pointer;

        .sanjiao {
            position: absolute;
            bottom: 0;
            width: 10px;
            height: 5px;
            left: calc(50% - 5px);
            background: transparent;
            border-style: solid;
            border-width: 0 10px 5px 10px;
            border-color: transparent transparent #fff transparent;
        }
    }
}

.biao {
    .one {
        flex: 1.2;
    }

    .two {
        flex: .8;
    }

    .three {
        flex: 3;
    }

    .four {
        flex: 1;
        text-align: center;
    }

    .five {
        flex: 1;
        text-align: center;
    }

    .six {
        flex: 1;
        text-align: center;
    }

    .bioatou {
        width: 100%;
        height: 53px;
        background: #E8EFFA;
        align-items: center;
        padding: 0 13px 0 27px;
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #0653AC;
        letter-spacing: 0;
        font-weight: 400;
    }

    .biaoshen {
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #191A1C;
        letter-spacing: 0;
        font-weight: 400;

        .hang:nth-child(even) {
            background: #F1F1F1;
        }

        .hang {
            padding: 0 13px 0 27px;
            min-height: 90px;
            cursor: pointer;
            justify-content: space-between;
            border-bottom: 1px dashed transparent;
            background: linear-gradient(#fff, #fff) padding-box,
                repeating-linear-gradient(-45deg, #D6DBE3 0, #D6DBE3 0.3em, #fff 0, #fff 0.6em);
            /* 0.3em设置虚线点的宽度或长度；0.6em设置虚线点的间距 */
        }
    }

    .qitren {
        width: 52px;
        height: 52px;
        padding: 3px;
        margin: 0 10px 0 0;
        background: #FFFFFF;
        border: 1px solid #D6DBE3;
    }

    .zixun {
        .zi {
            color: #2B317F;
        }

        .lv {
            color: #00A576;
        }

        .hong {
            color: #FF5637;
        }
    }

    .sta {
        .hong {
            color: #C90E0E;
        }

        .cheng {
            color: #FB8A2B;
        }

        .lv {
            color: #04AF23;
        }
    }
}

.act {
    background: #2670C5 !important;
    font-size: 15px !important;
    color: #FFFFFF !important;
}

.fontSix {
    font-family: 'Arial Black', 'Arial Normal', 'Arial', sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 16px;
}

.fontSeven {
    font-family: 'Arial Black', 'Arial Normal', 'Arial', sans-serif;
    font-style: normal;
    font-size: 14px;
}

.fontEight {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #7B8391;
    letter-spacing: 0;
    font-weight: 400;
}
</style>