// 他人信息
import request from '../request'

export default class Other {
    /**
* 个人主页
* @param {*} params
* @returns
*/
    static userOtherpage(params) {
        return request.get("/api/v1.usercontrol/userOtherpage", { params })
    }
    /**
*  个人关注、收藏、粉丝列表
* @param {*} params
* @returns
*/
    static userFolcomfans(params) {
        return request.get("/api/v1.usercontrol/userFolcomfans", { params })
    }
    /**
* 关注操作
* @param {*} params 
* @returns 
*/
    static useFollow(params) {
        return request.get("/api/v1.usercontrol/useFollow", {
            params
        })
    }
    /**
*  他人发帖
* @param {*} params
* @returns
*/
    static otherAcrtile(params) {
        return request.get("/api/v1.usercontrol/otherAcrtile", { params })
    }
    /**
*  他人收藏
* @param {*} params
* @returns
*/
    static otherFolcomcollts(params) {
        return request.get("/api/v1.usercontrol/otherFolcomcollts", { params })
    }
    /**
*  他的粉丝
* @param {*} params
* @returns
*/
    static otherFolcomfans(params) {
        return request.get("/api/v1.usercontrol/otherFolcomfans", { params })
    }
    /**
*  他的关注
* @param {*} params
* @returns
*/
    static otherFolcomfollows(params) {
        return request.get("/api/v1.usercontrol/otherFolcomfollows", { params })
    }
}