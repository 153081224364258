<!-- 他人资料 -->
<template>
  <div class="other">
    <!-- 导航标签 -->
    <div class="flex biao align-items">
      <!-- <div>{{ '当前位置 : ' }}</div>
            <div style="opacity: 0;">{{ '0' }}</div>
            <div class="">{{ '首页' }}</div>
            <div class="zuojian">
                <img src="../../assets/img/xinban/zuojian.png" class="w-full h-full" alt="">
            </div>
            <div class="yanse">{{ '他人资料' }}</div> -->
    </div>
    <!-- 发布主题与撤回 -->
    <div class="chehui flex space-between align-items">
      <div class="flex">
        <el-button type="primary" @click="fabu">发表新帖</el-button>
      </div>
      <div class="flex align-items che cursor_pointer" @click="fanhui">
        <!-- <img src="../../assets/img/xinban/fanhui.png" class="huitui" alt="">
                <div>{{ '返回列表' }}</div> -->
      </div>
    </div>
    <!-- 他人资料 -->
    <div class="taren">
      <div class="flex guan">
        <div class="guanzhu" @click="guanz(otherUser.followtag)">
          <span v-if="otherUser == null ? 0 : otherUser.followtag == 1">{{
            "已关注"
          }}</span>
          <span v-else>{{ "未关注" }}</span>
        </div>
      </div>
      <div class="flex">
        <div class="touxiang">
          <img
            src="~@/assets/img/one/weizhiyonghu.png"
            style="width: 100%; height: 100%"
            alt=""
            v-if="otherUser == null ? '' : otherUser.image == ''"
          />
          <img
            :src="
              otherUser == null
                ? '~@/assets/img/one/weizhiyonghu.png'
                : otherUser.image
            "
            style="width: 100%; height: 100%"
            alt=""
            v-else
          />
        </div>
        <div style="padding: 5px 0 0 0">
          <div class="name">{{ otherUser == null ? "" : otherUser.name }}</div>
          <div class="flex align-items">
            <!-- 主题 -->
            <div class="flex column align-items">
              <div class="fontOne">
                {{ otherUser == null ? 1 : otherUser.zhuti }}
              </div>
              <div class="fontTwo">{{ "主题" }}</div>
            </div>
            <div class="xian"></div>
            <!-- 回帖 -->
            <div class="flex column align-items">
              <div class="fontOne">
                {{ otherUser == null ? 2 : otherUser.huitie }}
              </div>
              <div class="fontTwo">{{ "回帖" }}</div>
            </div>
            <div class="xian"></div>
            <!-- 收藏 -->
            <div class="flex column align-items">
              <div class="fontOne">
                {{ otherUser == null ? 0 : otherUser.collection }}
              </div>
              <div class="fontTwo">{{ "收藏" }}</div>
            </div>
            <div class="xian"></div>
            <!-- 关注 -->
            <div class="flex column align-items">
              <div class="fontOne">
                {{ otherUser == null ? 0 : otherUser.follow }}
              </div>
              <div class="fontTwo">{{ "关注" }}</div>
            </div>
            <div class="xian"></div>
            <!-- 获赞 -->
            <div class="flex column align-items">
              <div class="fontOne">
                {{ otherUser == null ? 0 : otherUser.fabulous }}
              </div>
              <div class="fontTwo">{{ "获赞" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 他人帖子 -->
    <div class="tarentiezi flex">
      <div class="zuo">
        <div class="biaoti">
          <img
            src="../../assets/img/xinban/tieyoudaohang.png"
            class="icon"
            alt=""
          />
          <div>{{ "帖友导航" }}</div>
        </div>
        <div class="btn w-full">
          <div
            class="anniu"
            @click="qiehuan(index)"
            v-for="(item, index) in liebiao"
            :key="index"
            :class="{ act: index == timuNum }"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="you">
        <post v-if="timuNum == 0" ref="post"></post>
        <collect v-else-if="timuNum == 1" ref="collect"></collect>
        <!-- <fans v-else-if="timuNum == 2" ref="fans"></fans> -->
        <guanzhu v-else-if="timuNum == 2" ref="guanzhu"></guanzhu>
      </div>
    </div>
    <!-- 页码 -->
    <div class="yema">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :page-sizes="[10, 20]"
        :page-size="100"
        layout=" prev, pager, next, sizes, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import fans from "./fans/index.vue"; //他的粉丝
import post from "./fatie/index.vue"; // 他人发帖
import collect from "./shoucang/index.vue"; //他人收藏
import guanzhu from "./guanzhu/index.vue"; // 他的关注
import API from "@/api/modules/other.js";
import Cookies from "js-cookie";
export default {
  components: {
    // fans,
    post,
    collect,
    guanzhu,
  },
  data() {
    return {
      liebiao: ["他的发帖", "他的收藏", "他的关注"],
      timuNum: 0,
      page: 1,
      zongye: 10,
      total: 100,
      otherUser: null,
    };
  },
  mounted() {
    localStorage.setItem("path", this.$route.path);
    this.userHomepage();
    document.getElementsByClassName(
      "el-pagination__jump"
    )[0].childNodes[0].nodeValue = "跳至";
    
  },
  methods: {
    // 返回列表页
    fanhui() {
      this.$router.go(-1);
    },
    // 关注
    async guanz(num) {
      if (this.verification(this.$route.path) == false) {
        return;
      }
      let a = 0;
      if (num == 0) {
        a = 1;
      } else {
        a = 0;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await API.useFollow({
        useid: this.$store.state.loginMessage.id,
        type: a,
      }).then((res) => {
        if (res.code == 1) {
          this.userHomepage();
        }
        this.loading.close();
      });
    },
    // 进入发布页面
    fabu() {
      this.jumps("issue", this.$store.state.loginMessage);
    },
    // 获取total
    totals(num) {
      this.total = num;
      // console.log(this.total, "22200");
    },
    // 他人信息
    async userHomepage() {
      // console.log(this.$store.state.loginMessage.id, "2223");
      let res = await API.userOtherpage({
        useid: this.$store.state.loginMessage.id,
      }).then((res) => {
        if (res.code == 1) {
          this.otherUser = res.data;
          document.title = `${this.otherUser.name}的个人空间`
        }
      });
    },
    // 切换列表
    qiehuan(num) {
      this.timuNum = num;
    },
    // 切换条目
    handleSizeChange(val) {
      if (this.timuNum == 0) {
        this.$refs.post.pageLines(val);
      } else if (this.timuNum == 1) {
        this.$refs.collect.pageLines(val);
      } else if (this.timuNum == 2) {
        // this.$refs.fans.pageLines(val);
        this.$refs.guanzhu.pageLines(val)
      } else if (this.timuNum == 3) {
        // this.$refs.guanzhu.pageLines(val);
      }
      // console.log(`每页 ${val} 条`);
    },
    // 切换页码
    handleCurrentChange(val) {
      if (this.timuNum == 0) {
        this.$refs.post.pages(val);
      } else if (this.timuNum == 1) {
        this.$refs.collect.pages(val);
      } else if (this.timuNum == 2) {
        // this.$refs.fans.pages(val);
        this.$refs.guanzhu.pages(val);
      } else if (this.timuNum == 3) {
        // this.$refs.guanzhu.pages(val);
      }
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped lang="scss">
.other {
  width: 1200px;
}

.biao {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  margin: 16px 0 22px 0;

  .zuojian {
    width: 18px;
    height: 18px;
    margin: 0 3px;
  }

  .yanse {
    color: #0653ac;
  }
}

.chehui {
  ::v-deep(.el-button--primary) {
    width: 110px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    border: 0px solid red;
    margin-right: 17px;
  }

  ::v-deep(.el-button--info) {
    width: 82px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
  }

  .che {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #2670c5;
    letter-spacing: 0;
    font-weight: 400;

    .huitui {
      width: 15.69px;
      height: 13.07px;
      margin-right: 6.15px;
    }
  }
}

.taren {
  background: #f4f8fd;
  border: 1px solid #d6dbe3;
  margin-top: 15px;
  padding: 18px 15px 29px 31px;

  .guan {
    width: 100%;
    justify-content: flex-end;

    .guanzhu {
      width: 62px;
      height: 26px;
      border: 1px solid #868d9d;
      border-radius: 17px;
      line-height: 26px;
      text-align: center;
      cursor: pointer;
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #858ea0;
      letter-spacing: 0;
      font-weight: 500;
    }
  }

  .touxiang {
    width: 110px;
    height: 110px;
    padding: 5px;
    background: #ffffff;
    border: 1px solid #d6dbe3;
    margin-right: 28px;
  }

  .name {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #34348f;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 32px;
  }

  .xian {
    width: 0.5px;
    height: 16px;
    border-left: 1px solid #d6dbe3;
    margin: 0 28px 0 30px;
  }

  .fontOne {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #27262f;
    letter-spacing: 0;
    font-weight: 400;
  }

  .fontTwo {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #484c51;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: 400;
  }
}

.tarentiezi {
  background: #ffffff;
  border: 1px solid #d6dbe3;
  min-height: 300px;
  margin-top: 14px;

  .zuo {
    width: 226px;
    border-right: 1px solid #d6dbe3;

    .biaoti {
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #0653ac;
      letter-spacing: 0;
      font-weight: 500;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }

    .anniu {
      height: 52px;
      line-height: 52px;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      color: #36383a;
      letter-spacing: 0;
      font-weight: 400;
      cursor: pointer;
    }
  }

  .you {
    flex: 1;
  }
}

.yema {
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: end;

  ::v-deep(.el-pager) {
    .number {
      background: #fff;
      border: 0.5px solid #d6dbe3;
    }
  }

  ::v-deep(.el-pagination__jump) {
    margin-left: 0 !important;
  }
}

.act {
  background: #e8effa !important;
}
</style>