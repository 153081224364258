<!-- 他人发帖 -->
<template>
  <div class="_postMessage">
    <div class="moban_tou flex align-items">
      <div
        class="fenlei"
        v-for="(item, index) in liebiao"
        :key="index"
        @click="qiehuan(index)"
        :class="{ act: timuNum == index }"
      >
        {{ item }}
        <div class="sanjiao"></div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="w-full biao">
      <!-- 表头 -->
      <div class="bioatou flex">
        <div class="one">{{ "编号" }}</div>
        <div class="two">{{ "诉求类型" }}</div>
        <div class="three">{{ "标题" }}</div>
        <div class="four">{{ "诉求时间" }}</div>
        <div class="five">{{ "受理单位" }}</div>
        <div class="six">{{ "处理状态" }}</div>
      </div>
      <div class="biaoshen">
        <!-- 模板 -->
        <div
          class="w-full flex hang align-items"
          v-for="(item, index) in list"
          :key="index"
          @click="xiangqing(item.cardsalt)"
        >
          <div class="one">{{ item.cardsalt }}</div>
          <div class="two zixun">
            <div class="zi" v-if="item.type == '咨询'">【{{ "咨询" }}】</div>
            <div class="hong" v-else-if="item.type == '投诉'">
              【{{ "投诉" }}】
            </div>
            <div class="lv" v-else>【{{ "建议" }}】</div>
          </div>
          <div class="three">{{ item.title }}</div>
          <div class="four">{{ item.articletime.slice(0, 10) }}</div>
          <div class="five">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.unit_name"
              placement="top-start"
              :disabled="gf(item.unit_name)"
            >
              <div>{{ buchang(item.unit_name) }}</div>
            </el-tooltip>
            <!-- {{ item.unit_name }} -->
          </div>
          <div class="six sta">
            <div class="lv" v-if="item.status_txt == '拟办结'">
              {{ "已回复" }}
            </div>
            <div class="lv" v-else>{{ item.status_txt }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/other.js";
export default {
  data() {
    return {
      liebiao: ["全部", "待受理", "已受理", "已回复", "已办结"],
      timuNum: 0,
      page: 1,
      pageLine: 10,
      num: 0, //类型
      list: [],
    };
  },
  mounted() {
    this.otherAcrtile(0);
  },
  methods: {
    // 判断是否有top提示
    gf(str) {
      let a = str.split(",");
      if (a.length >= 2) {
        return false;
      } else {
        return true;
      }
    },
    // 部门长度
    buchang(str) {
      let a = str.split(",");
      if (a.length == 1) {
        return a.toString();
      } else {
        return a[0] + "," + "...";
      }
    },
    // 跳转至详情
    xiangqing(id) {
      // console.log(id, "2223");
      this.$store.state.loginMessage.cardsalt = id;
      this.jump("details", this.$store.state.loginMessage);
    },
    async otherAcrtile(num) {
      this.num = num;
      let res = await API.otherAcrtile({
        useid: this.$store.state.loginMessage.id,
        kind: num,
        page: this.page,
        pageLine: this.pageLine,
      }).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list;
          this.$parent.totals(res.data.total);
        }
      });
    },
    //切换状态
    qiehuan(num) {
      this.timuNum = num;
      this.otherAcrtile(num);
    },
    pages(num) {
      this.page = num;
      this.otherAcrtile(this.num);
    },
    pageLines(num) {
      this.pageLine = num;
      this.otherAcrtile(this.num);
    },
  },
};
</script>

<style scoped lang="scss">
._postMessage {
  width: 100%;
}

.moban_tou {
  height: 56px;

  .fenlei {
    height: 100%;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #36383a;
    letter-spacing: 0;
    font-weight: 500;
    padding: 0 29px 0 37px;
    line-height: 61px;
    position: relative;
    cursor: pointer;

    .sanjiao {
      position: absolute;
      bottom: 0;
      width: 10px;
      height: 5px;
      left: calc(50% - 5px);
      background: transparent;
      border-style: solid;
      border-width: 0 10px 5px 10px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

.biao {
  .one {
    flex: 1.2;
  }

  .two {
    flex: 0.8;
  }

  .three {
    flex: 3;
  }

  .four {
    flex: 1;
    text-align: center;
  }

  .five {
    flex: 1;
    text-align: center;
  }

  .six {
    flex: 1;
    text-align: center;
  }

  .bioatou {
    width: 100%;
    height: 52px;
    background: #e8effa;
    align-items: center;
    padding: 0 13px 0 27px;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #0653ac;
    letter-spacing: 0;
    font-weight: 400;
  }

  .biaoshen {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;

    .hang:nth-child(even) {
      background: #f1f1f1;
    }

    .hang {
      padding: 0 13px 0 27px;
      min-height: 53px;
      cursor: pointer;
    }
  }

  .zixun {
    .zi {
      color: #2b317f;
    }

    .lv {
      color: #00a576;
    }

    .hong {
      color: #ff5637;
    }
  }

  .sta {
    .hong {
      color: #c90e0e;
    }

    .cheng {
      color: #fb8a2b;
    }

    .lv {
      color: #04af23;
    }
  }
}

.act {
  background: #2670c5 !important;
  font-size: 15px !important;
  color: #ffffff !important;
}
</style>